/**
 * Dynamic finance table
 */
((window, document) => {
  /**
   * Representative finance offer attribute
   * @typedef {Object} OfferAttribute
   * @property {string} attrLabel The attribute's label
   * @property {string} attrValue The attribute's value
   * @property {number} attrSeq The attribute's sequence
   * @property {number} attrSort
   * @property {number} offerSeq
   */

  /**
   * Representative finance offer detail
   * @typedef {Object} OfferDetail
   * @property {OfferAttribute[]} [offerAttribute] The offer attributes to display in the table
   * @property {string} offerGroup The offer's group ex. PCP | HP
   * @property {string} offerHeadline The offer's vehicle description
   * @property {number} offerSeq The sequence the offer should be displayed
   * @property {string} [fallbackDtl] Fallback html text to be displayed if there is no offer
   * @property {'YES' | 'NO'} offerActive If the offer is active
   */

  /**
   * Decode an html and return only the text value
   * @param {string} value The html to decode
   */
  function decodeHtml(value) {
    const tempEl = document.createElement('div');
    tempEl.innerHTML = value;
    return tempEl.innerText;
  }

  /**
   * Generate a table row for the finance table
   *
   * @param {string} label The label of the row
   * @param {string} value The value of the row
   *
   * @returns {HTMLTableRowElement}
   */
  function generateFinanceTableRow(label, value) {
    const row = document.createElement('tr');
    row.innerHTML = `
      <th>${label}</th>
      <td><strong>${value}</strong></td>
    `;

    return row;
  }

  /**
   * Generate the top and main rows of the finance tale in DocumentFragments and return them
   *
   * @param {OfferDetail} offerDetail
   */
  function generateTopMainFinanceTable(offerDetail) {
    const tableTopFragment = document.createDocumentFragment();
    const tableMainFragment = document.createDocumentFragment();

    offerDetail.offerAttribute.sort((a, b) => a.attrSort - b.attrSort);
    offerDetail.offerAttribute.forEach((attr) => {
      if (attr.attrValue !== '') {
        // Display the first 4 items on top row and all the rest to the main row
        const table = attr.attrSort <= 4 ? tableTopFragment : tableMainFragment;

        table.appendChild(generateFinanceTableRow(attr.attrLabel, attr.attrValue));
      }
    });

    return {
      tableTopFragment,
      tableMainFragment,
    };
  }

  /**
   * Generate and render the finance table on the DOM based on the offerDetail
   *
   * @param {HTMLElement} financeTableContainer
   * @param {OfferDetail} offerDetail
   */
  function generateFinanceTable(financeTableContainer, offerDetail) {
    const tableTop = financeTableContainer.querySelector('.js-rep-finance-top');
    const tableMain = financeTableContainer.querySelector('.js-rep-finance-main');
    const tableFallback = financeTableContainer.querySelector('.js-rep-finance-fallback');
    const tableDescription = financeTableContainer.querySelector('.js-rep-finance-desc');

    tableTop.textContent = '';
    tableMain.textContent = '';
    tableFallback.textContent = '';
    tableDescription.textContent =
      offerDetail.offerHeadline !== '' ? `: ${offerDetail.offerHeadline}` : '';

    // Generate top and main table rows
    if (offerDetail.offerAttribute) {
      tableTop.classList.remove('hide__default');
      tableMain.classList.remove('hide__default');

      const { tableMainFragment, tableTopFragment } = generateTopMainFinanceTable(offerDetail);
      tableTop.appendChild(tableTopFragment);
      tableMain.appendChild(tableMainFragment);
    } else if (offerDetail.fallbackDtl !== '') {
      // Else generate fallback content
      tableTop.classList.add('hide__default');
      tableMain.classList.add('hide__default');

      const fallbackContainer = document.createElement('div');
      fallbackContainer.classList.add('finance-table__fallback-container');
      fallbackContainer.textContent = decodeHtml(offerDetail.fallbackDtl);
      tableFallback.appendChild(fallbackContainer);
    }
  }

  /**
   * Handle the toggle events when a switch happens to the toggle items
   *
   * @param {HTMLElement} financeTableContainer The finance table container that contains the toggle
   * @param {OfferDetail[]} offerDetails The offer details from the representative finance example
   * @param {CustomEvent<{
   *  previousValue: string,
   *  currentValue: string,
   * container: HTMLElement;
   * }>} event The triggered custom event
   */
  function toggleHandler(financeTableContainer, offerDetails, event) {
    if (event.detail.currentValue) {
      const selectedOffer = offerDetails.find(
        (offer) => offer.offerGroup === event.detail.currentValue,
      );
      generateFinanceTable(financeTableContainer, selectedOffer);
    }
  }

  /**
   * Generate the toggle items based on the provided offer details
   *
   * //The finance table container that the toggle will be appended
   * @param {HTMLElement} financeTableContainer
   * @param {OfferDetail[]} offerDetails The offer details from the representative finance example
   */
  function generateToggleItems(financeTableContainer, offerDetails) {
    const toggleContainer = financeTableContainer.querySelector('.js-rep-finance-toggle');

    if (!toggleContainer) return;

    const toggleGeneric = document.createElement('div');
    toggleGeneric.classList.add('toggle-generic');

    toggleGeneric.innerHTML = '<span class="toggle-generic__slide"></span>';

    let toggleItems = '';

    offerDetails.forEach((offer) => {
      toggleItems += `
        <input id="toggle-${offer.offerGroup}" class="toggle-generic__input" name="finance-toggle" value="${offer.offerGroup}" type="radio">
        <label for="toggle-${offer.offerGroup}" class="toggle-generic__label">
            ${offer.offerGroup}
        </label>
      `;
    });

    toggleGeneric.insertAdjacentHTML('beforeend', toggleItems);

    toggleContainer.appendChild(toggleGeneric);

    window.toggleGeneric.init(toggleGeneric);

    // Bind the toggle handler with the financeTableContainer
    // and offerDetails to be passed as first 2 arguments,
    // the event will be passed as a 3rd argument instead of the 1st argument
    toggleGeneric.addEventListener(
      'js-toggle-generic-finance-toggle',
      toggleHandler.bind(null, financeTableContainer, offerDetails),
      false,
    );
  }

  /**
   * Initialize the dynamic finance table
   *
   * @param {HTMLElement} financeTableContainer The finance table container
   * @param {OfferDetail[]} offerDetails
   */
  function init(financeTableContainer, offerDetails) {
    const noFinanceTableToggle = document.querySelector('.js-finance-table--no-toggle');

    // If noFinanceTableToggle is NOT present in the document, generate toggle
    if (!noFinanceTableToggle) {
      generateToggleItems(financeTableContainer, offerDetails);
    }
    generateFinanceTable(financeTableContainer, offerDetails[0]);
  }

  window.vm.onload(() => {
    if (typeof window.repFinance !== 'undefined') {
      const financeTable = document.querySelector('.js-rep-finance-table');
      init(financeTable, window.repFinance.representativeFinance.myOfferData.offerDetail);
    }
  });

  window.dynamicFinanceTable = {
    init,
  };
})(window, document);
